@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,400&display=swap");

body {
  background: #fff;
  font-family: "Inter", sans-serif;
  /* font-family: 'Nunito', sans-serif; */
  overflow-x: hidden;
}

div.infinite-scroll-component {
  padding: 0px 50px;
  margin: 0px -50px;
  overflow: hidden !important;
}
